import React, {Component} from 'react';
import Slider from 'react-slick';

 class TestimonialSlider extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 1800,
        arrows: false,
        autoplay: true,
        pauseOnHover:true,
        autoplaySpeed: 5500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <div>
          <Slider {...settings} className="testimonial_slider">
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/victor.png')} alt=""/>
                    </div>
                    <h6>Victor Leopoldo Castillejo Rivero - Pensu.io</h6>
                    <span>Head Of Business Development at Soft For Business</span>
                    <p>Ante desafíos tecnológicos importantes que exigieron dominar el procesamiento de plataformas y aplicaciones de seguridad en el ambiente AWS hemos
                       logrado un trabajo de exelencia con el trabajo de Sistmasdev. Su rápidez, eficacia y tino en lo inherente a Dynamo DB, Lambdas, Elastic Search, Cognito, S3 y
                       en fín todas las herramientas de Back End de AWS son de primer nivel.</p>
                </div>
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/axyomaglobal.png')} alt=""/>
                    </div>
                    <h6>Nahuel Farro Gasparutti - Axyoma Global</h6>
                    <span>Agencia Digital</span>
                    <p>Sistemasdev es un partner que ha demostrado estar a la altura de cada desafío. Desde Axyoma Global generamos una sinergia que nos permite hoy trabajar en conjunto en diversos proyectos que son de suma importancia para nosotros. Integro, profesional y sobre todo, gran calidad humana convierten a Sistemasdev en una parte fundamental de nuestro día a día. Sigamos creciendo juntos.  </p>
                </div>
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/oveja-negra.png')} alt=""/>
                    </div>
                    <h6>Marilina Páez - Oveja Negra</h6>
                    <span>Diseño gráfico</span>
                    <p>Sistemasdev ha sido una gran oveja negra! Es muy valorable que te dediquen tiempo, sobre todo cuando no sabes muchas cosas técnicas. Ivan tiene la paciencia para explicarte y ofrecerte la solucion acorde a lo que necesita cada proyecto, y siempre con un buen costo! Ivan es un gran profesional, pero por sobre todo una muy buena persona y un gran aliado para cualquier equipo.</p>
                </div>
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/Sidney-Rees.jpg')} alt=""/>
                    </div>
                    <h6>Sidney Rees</h6>
                    <span>Senior IT Project Manager</span>
                    <p>Trabajé con Sistemasdev en Optimous.com. Iván es un desarrollador muy prolijo, eficaz, con gran conocimiento en el área. Siempre en la búsqueda continua de mejora y con una gran capacidad de investigación. Trabaja muy bien en grupo brindando sus conocimientos y colaborando en llegar a los objetivos. Cualquier equipo que integre va a estar favorecido por sus aportes. </p>
                </div>
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/teresa-viera.jpg')} alt=""/>
                    </div>
                    <h6>Teresa Viera</h6>
                    <span>Escritora</span>
                    <p>Trabajar con Sistemasdev fue una experiencia maravillosa. Porque no sólo el trabajo que realizan es excelente, sino que te brindan una atención personalizada y te acompañan en todo el proceso.</p>
                </div>
          </Slider>
        </div>
      );
    }
  }
  export default TestimonialSlider;