const postUno = {
    "titulo": "Primer post.",
    "subtitulo": "Este es el subtitulo.",
    "name": "SistemasDev.",
    "name2": "Desarrollo de sistemas a medida.",
    "name3": "Brindamos desarrollos con las últimas tecnologías",
    "role": "Desarrollo de sistemas",
    "ptext": "Agencia dedicada al desarrollo profesional de sistemas.",
    "socialLinks":[
        {
          "name": "Facebook",
          "url": "http://www.linkedin.com/",
          "className": "social_facebook"
        },
        {
          "name":"twitter",
          "url":"http://www.twitter.com/",
          "className":"social_twitter"
        },
        {
          "name":"googleplus",
          "url":"http://www.googleplus.com",
          "className":"social_googleplus"
        },
        {
            "name":"pinterest",
            "url":"http://www.pinterest.com",
            "className":"social_pinterest"
          }
      ],
      "aboutme": "En SistemasDev",
      "aboutdetails": "Estamos al pendiente de todo lo nuevo del mercado, nuestra empresa se esfuerza constantemente en reducir costos y brindar lo óptimo para que el sistema final cumpla todos los requerimientos necesarios, ni más ni menos. Nos aseguramos que el desarrollo otorgado sea garantizado en su totalidad con la eficiencia, eficacia y calidad máxima para los requerimientos planteados. ",
      "service":[
          {
            "id" : 1,
            "iconName": "icon-desktop",
            "serviceTitle": "Diseño web",
            "sDetails" : "Los especialistas en diseño web se encargarán de plasmar la idea y crear una entidad única."
          },
          {
            "id" : 2,
            "iconName": "icon-pencil",
            "serviceTitle": "Desarrollo",
            "sDetails" : "Trasladamos el diseño a sistemas sencillos para el usuario y calidad superior para todo tipos de dispositivos."
          },
          {
            "id" : 3,
            "iconName": "icon-presentation",
            "serviceTitle": "Seguimiento",
            "sDetails" : "Brindamos un seguimiento continuo del sistema y estudiamos su comportamiento para mejorarlo de ser necesario."
          },
          {
            "id" : 4,
            "iconName": "icon-linegraph",
            "serviceTitle": "Strategy",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 5,
            "iconName": "icon-gears",
            "serviceTitle": "Support",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 6,
            "iconName": "icon-tools",
            "serviceTitle": "We’re experienced",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: Z105 - Tan Thinh, Thai Nguyen - Viet Nam',
            },
            {
              id: 2,
              icon: 'icon_phone',
              text: 'Phone: +1.900.3456.789',
            },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: yourname@somemail.com',
            },
            {
              id: 4,
              icon: 'icon_globe',
              text: 'Website: http://example.com',
            }
          ]
        },
      ],
    contacttitle: 'Contact Form',
}

export default postUno;

