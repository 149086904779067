import React, {Component} from 'react';
import Particles from '../Banner/Particles';
import Reveal from 'react-reveal/Reveal/';
import Typer from '../Banner/Typer';

class Header extends Component {
    render(){
        let data = this.props.data;
        return(
            <section id="home">
                <div className="banner_area_two">
                    <div className="one_img" data-parallax='{"x": 110, "y": 150, "rotateZ":0}'><img className="faa-spin animated" src={require('../../image/circle-2.png')} alt=""/></div>
                    <Particles/>
                    <div className="container">
                        <div className="banner_content">
                            <Reveal effect="fadeInUp">
                                <h2 className="wow fadeInLeft animated">{data.titulo}</h2>
                            </Reveal>
                            <Reveal effect="fadeInUp" duration={1500}>
                                <h3>{data.subtitulo}</h3>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Header;