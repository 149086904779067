import React, {Component} from 'react';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';
class ServiceTwo extends Component{
    render(){
        let {rowFlex} = this.props;
        return(
            <div className="service_area_two bg_color" id="service">
                <div className="container">
                    <div className={`row align-items-center ${rowFlex}`}>
                        <div className="col-lg-5">
                            <div className="service_content">
                                <SectionTitleTwo stitle="Un poco de mi ?" btitle="Fánatico de los nuevos desafios."/>
                                <p>Desarrollo sistemas hace 7 años <span>y me propongo </span>dia a dia mejorar un poco más. Amo lo que hago y agradezco al universo la posibilidad de hacerlo.</p>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab"   role="tab" aria-controls="home" aria-selected="true">
                                            Tengo 39 años
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="profile-tab"   role="tab" aria-controls="profile" aria-selected="false">
                                            Padre de familia y esposo &#128149;
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab"   role="tab" aria-controls="contact" aria-selected="false">
                                            Mitlitar retirado de FFAA
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab"   role="tab" aria-controls="contact" aria-selected="false">
                                            Como mencione, BACKEND EMPEDERNIDO &#128513;
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab"   role="tab" aria-controls="contact" aria-selected="false">
                                            Como deportista, buen FRONTEND  &#128540;
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab"   role="tab" aria-controls="contact" aria-selected="false">
                                            Estudiante vitalicio
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="service_img">
                                        <img src={require ('../image/service.svg')} alt=""/>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="service_img">
                                        <img src={require ('../image/service.svg')} alt=""/>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className="service_img">
                                        <img src={require ('../image/service.svg')} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceTwo;
