import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Fade, Reveal} from 'react-reveal/';

class Content extends Component {
    render(){
        let ifontanaData = this.props.ifontanaData; 
        var {aClass} = this.props;
        return(
            <section className={`${aClass}`} id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 d-flex align-items-center">
                            <Fade bottom cascade duration={1000}>
                                <div className="about_content">
                                    <p>{ifontanaData.aboutdetails}</p>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-2">
                            
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Content;
