import React from 'react';
import Navbar from '../component/Navbar/Navbar';
import Header from '../component/Post/header';
import Content from '../component/Post/content';
import Contact from '../component/Contact';
import Footer from '../component/Footer';
import postUno from '../posts/data';


export const PostUno = () => (
  <div className="body_wrapper">
      <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo2.png" stickylogo="logo.png"/>
      <Header data={postUno}/>
      <Content aClass='about_area bg_color' ifontanaData={postUno}/>
     
      <Contact ifontanaData={postUno}/>
      <Footer ifontanaData={postUno}/>
  </div>
)


