const ifontanaData = {
    "name": "SistemasDev.",
    "name2": "Desarrollo de sistemas a medida.",
    "name3": "Brindamos desarrollos con las últimas tecnologías",
    "role": "Desarrollo de sistemas",
    "ptext": "Agencia dedicada al desarrollo profesional de sistemas.",
    "socialLinks":[
        {
          "name": "Facebook",
          "url": "http://www.linkedin.com/",
          "className": "social_facebook"
        },
        {
          "name":"twitter",
          "url":"http://www.twitter.com/",
          "className":"social_twitter"
        },
        {
          "name":"googleplus",
          "url":"http://www.googleplus.com",
          "className":"social_googleplus"
        },
        {
            "name":"pinterest",
            "url":"http://www.pinterest.com",
            "className":"social_pinterest"
          }
      ],
      "aboutme": "En SistemasDev",
      "aboutdetails": "Somos un equipo de profesionales que trabajamos conjuntamente para desarrollar e integrar soluciones óptimas. ",
      "aboutdetails2": "Estamos al pendiente de todo lo nuevo del mercado, nuestra empresa se esfuerza constantemente en reducir costos y brindar la mejor calidad y servicio.",
      "aboutdetails3":"Nos aseguramos que el sistema otorgado sea garantizado en su totalidad con la eficiencia, eficacia y calidad máxima según los requerimientos planteados. ",
      "service":[
          {
            "id" : 1,
            "iconName": "icon-linegraph",
            "serviceTitle": "Estrategia",
            "sDetails" : "El cliente tomará la decisión de aceptar o no algunas de las soluciones  rápidas y efectivas brindadas por la empresa para validar las ideas."            
          },
          {
            "id" : 2,
            "iconName": "icon-desktop",
            "serviceTitle": "Diseño web",
            "sDetails" : "Los especialistas en diseño web y comunicación se encargarán de plasmar la idea y crear una entidad única para su empresa."
          },
          {
            "id" : 3,
            "iconName": "icon-pencil",
            "serviceTitle": "Desarrollo",
            "sDetails" : "Trasladamos el diseño a sistemas sencillos para el usuario y \n\r calidad superior para todo tipos de dispositivos."
          },
          {
            "id" : 4,
            "iconName": "icon-presentation",
            "serviceTitle": "Seguimiento",
            "sDetails" : "Brindamos un seguimiento continuo del sistema y estudiamos su comportamiento para mejorarlo de ser necesario."
          },
          {
            "id" : 5,
            "iconName": "icon-gears",
            "serviceTitle": "Soporte",
            "sDetails" : "Estamos a disposición del cliente para brindar asesoramiento, responder sus dudas y estar al pendiente para que obtengan la mejor experiencia posible."
          },
          {
            "id" : 6,
            "iconName": "icon-tools",
            "serviceTitle": "Mejora continua",
            "sDetails" : "Día a día nos preocupamos por capacitarnos en las mejores tecnologías del mercado y buscar la optimización de todos nuestros sistemas."
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Información de contacto",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'San Benito, Paraná, Entre Rios CP3107',
            },
            {
              id: 2,
              icon: 'icon_phone',
              text: 'Celular: +54 9 343 5 134 624',
            },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: contacto@sistemasdev.com',
            },
            {
              id: 4,
              icon: 'icon_globe',
              text: 'Website: https://sistemasdev.com',
            }
          ]
        },
      ],
    contacttitle: 'Formulario de contacto',
}

export default ifontanaData;

